<template>
  <div>
    <div class="card siteCard mt-3">
      <div class="p-3">
        <div class="row">
          <div class="col-md-2">
            <div>
              <p class="creatorTitle">Total Stream Count</p>
              <h3 class="my-2">
                <span class="text-dark font-weight-bold">{{
                  analyticsData.total_streams | number("0,0")
                }}</span>
              </h3>
            </div>

            <div>
              <p class="creatorTitle">Total Downloads</p>
              <h3 class="my-2">
                <span class="text-dark font-weight-bold">{{
                  analyticsData.total_downloads | number("0,0")
                }}</span>
              </h3>
            </div>

            <div>
              <p class="creatorTitle">Total Earnings</p>
              <h3 class="my-2">
                <span class="text-dark font-weight-bold">{{
                  analyticsData.earnings || 0 | currency("€", 2)
                }}</span>
              </h3>
            </div>
          </div>
          <div class="col-md-2">
            <div>
              <p class="creatorTitle">Total Products</p>
              <h3 class="my-2">
                <span clavss="text-dark font-weight-bold">{{
                  analyticsData.products
                }}</span>
              </h3>
            </div>
            <div>
              <p class="creatorTitle">Total Incoming</p>
              <h3 class="my-2">
                <span class="text-dark font-weight-bold">{{
                  analyticsData.incoming
                }}</span>
              </h3>
            </div>
            <div>
              <p class="creatorTitle">Total Artists</p>
              <h3 class="my-2">
                <span class="text-dark font-weight-bold">{{
                  analyticsData.artists
                }}</span>
              </h3>
            </div>
          </div>

          <div class="col-md-8">
            <v-chart autoresize class="infoChart" :option="infoChart" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card siteCard mt-3" id="div-with-loading">
          <div class="p-3">
            <div class="row align-items-center mb-4">
              <div class="col-md-9">
                <h4 class="text-dark d-inline-block m-0">
                  Streams of Asset and Products
                </h4>
              </div>
              <div class="col-md-3">
                <div>
                  <date-range-picker
                    ref="picker"
                    class="rangePicker"
                    opens="right"
                    :locale-data="{
                      firstDay: 1,
                      format: 'dd-mm-yyyy HH:mm:ss',
                    }"
                    singleDatePicker="range"
                    :autoApply="true"
                    v-model="dateRange"
                    :linkedCalendars="true"
                    @update="onDateSelected"
                  >
                    <template v-slot:input="picker">
                      {{
                        moment
                          .utc(new Date(picker.startDate))
                          .format("DD/MM/YYYY")
                      }}
                      -
                      {{
                        moment
                          .utc(new Date(picker.endDate))
                          .format("DD/MM/YYYY")
                      }}
                    </template>
                  </date-range-picker>
                </div>
              </div>
            </div>

            <div class="row" id="stream-with-loading">
              <div class="col-md-12">
                <v-chart
                  autoresize
                  class="streamChart mb-4"
                  height="500"
                  :option="streamChart"
                />
              </div>

              <div class="col-md-2">
                <div>
                  <p class="creatorTitle">Total Stream Count</p>
                  <h3 class="my-2">
                    <span class="text-dark font-weight-bold">{{
                      streamsData.totals &&
                      streamsData.totals.stream_count | number("0,0")
                    }}</span>
                  </h3>
                </div>

                <div>
                  <p class="creatorTitle">Stream Percent</p>
                  <h3 class="my-2">
                    <span
                      class="font-weight-bold"
                      :class="{
                        'text-success':
                          streamsData.totals &&
                          streamsData.totals.stream_percentage > 0,
                        'text-danger':
                          streamsData.totals &&
                          streamsData.totals.stream_percentage < 0,
                      }"
                      >{{
                        streamsData.totals &&
                        streamsData.totals.stream_percentage | number("0,00")
                      }}
                      %</span
                    >
                  </h3>
                </div>
              </div>
              <div class="col-md-7">
                <table
                  class="table align-items-center table-flush"
                  v-if="streamsData.list"
                >
                  <thead class="">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Totals</th>
                      <th scope="col">Stream Changes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in streamsData.list" :key="index">
                      <td>
                        <p class="text-dark mb-0">{{ index + 1 }}</p>
                      </td>
                      <td>
                        <p class="text-dark">{{ data.name }}</p>
                      </td>
                      <td>
                        <p class="text-dark">
                          {{ data.totals && data.totals.count | number("0,0") }}
                        </p>
                      </td>
                      <td>
                        <p
                          :class="` ${
                            data.totals && data.totals.percentage < 0
                              ? 'text-danger'
                              : 'text-success'
                          }`"
                        >
                          {{
                            data.totals &&
                            data.totals.percentage | number("0.00")
                          }}%
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card siteCard mt-3" id="div-with-loading">
          <div class="p-3">
            <div class="row align-items-center mb-4">
              <div class="col-md-9">
                <h4 class="text-dark d-inline-block m-0">
                  Downloads of Asset and Products
                </h4>
              </div>
              <div class="col-md-3">
                <div>
                  <date-range-picker
                    ref="pickerDownloads"
                    class="rangePicker"
                    opens="right"
                    :locale-data="{
                      firstDay: 1,
                      format: 'dd-mm-yyyy HH:mm:ss',
                    }"
                    singleDatePicker="range"
                    :autoApply="true"
                    v-model="dateRangeDownloads"
                    :linkedCalendars="true"
                  >
                    <template v-slot:input="pickerDownloads">
                      {{
                        moment
                          .utc(new Date(pickerDownloads.startDate))
                          .format("DD/MM/YYYY")
                      }}
                      -
                      {{
                        moment
                          .utc(new Date(pickerDownloads.endDate))
                          .format("DD/MM/YYYY")
                      }}
                    </template>
                  </date-range-picker>
                </div>
              </div>
            </div>

            <div class="row" id="download-with-loading">
              <div class="col-md-12">
                <v-chart
                  class="streamChart mb-4"
                  height="500"
                  :option="downloadChart"
                />
              </div>

              <div class="col-md-2">
                <div>
                  <p class="creatorTitle">Total Downloads Count</p>
                  <h3 class="my-2">
                    <span class="text-dark font-weight-bold">{{
                      DownloadsData.totals &&
                      DownloadsData.totals.download_count | number("0,0")
                    }}</span>
                  </h3>
                </div>

                <div>
                  <p class="creatorTitle">Download Percent</p>
                  <h3 class="my-2">
                    <span
                      class="font-weight-bold"
                      :class="{
                        'text-success':
                          DownloadsData.totals &&
                          DownloadsData.totals.download_percentage > 0,
                        'text-danger':
                          DownloadsData.totals &&
                          DownloadsData.totals.download_percentage < 0,
                      }"
                      >{{
                        DownloadsData.totals &&
                        DownloadsData.totals.download_percentage
                          | number("0,00")
                      }}
                      %</span
                    >
                  </h3>
                </div>
              </div>
              <div class="col-md-7">
                <table
                  class="table align-items-center table-flush"
                  v-if="DownloadsData.list"
                >
                  <thead class="">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Totals</th>
                      <th scope="col">Stream Changes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in DownloadsData.list"
                      :key="index"
                    >
                      <td>
                        <p class="text-dark mb-0">{{ index + 1 }}</p>
                      </td>
                      <td>
                        <p class="text-dark">{{ data.name }}</p>
                      </td>
                      <td>
                        <p class="text-dark">
                          {{ data.totals && data.totals.count | number("0,0") }}
                        </p>
                      </td>
                      <td>
                        <p
                          :class="` ${
                            data.totals && data.totals.percentage < 0
                              ? 'text-danger'
                              : 'text-success'
                          }`"
                        >
                          {{
                            data.totals &&
                            data.totals.percentage | number("0.00")
                          }}%
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  name: "AnalyticsIndex",
  data() {
    return {
      loading: false,
      analyticsData: {},
      streamsData: {},
      DownloadsData: {},
      infoChart: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            color: [
              "#f88f80",
              "#f8c9a0",
              "#f8f0a0",
              "#a0f8a0",
              "#a0f8f0",
              "#f88f50",
            ],
            itemStyle: {
              borderRadius: 5,
            },
            data: [],

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0)",
              },
            },
          },
        ],
      },
      dateRange: {
        startDate: "",
        endDate: "",
      },
      dateRangeItems: {
        startDate: "",
        endDate: "",
      },
      dateRangeDownloads: {
        startDate: "",
        endDate: "",
      },
      dateRangeItemsDownloads: {
        startDate: "",
        endDate: "",
      },
      streamChart: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            name: "Streams",
            type: "bar",
            color: "#f8c9a0",
            data: [],
          },
        ],
      },
      downloadChart: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            name: "Downloads",
            type: "bar",
            color: "#f88f80",
            data: [],
          },
        ],
      },
    };
  },
  components: { DateRangePicker },
  mounted() {
    this.dateRange.startDate = moment()
      .subtract(1, "years")
      .format("YYYY-MM-D");
    this.dateRange.endDate = moment().format("YYYY-MM-D");
    this.dateRangeDownloads.startDate = moment()
      .subtract(1, "years")
      .format("YYYY-MM-D");
    this.dateRangeDownloads.endDate = moment().format("YYYY-MM-D");
    this.getAnalytics();
    this.getStreamsData();
    this.getDownloadsData();
  },
  watch: {
    "dateRange.startDate": function () {
      this.getStreamsData(true);
    },
    "dateRange.endDate": function () {
      this.getStreamsData(true);
    },
    "dateRangeDownloads.startDate": function () {
      this.getDownloadsData(true);
    },
    "dateRangeDownloads.endDate": function () {
      this.getDownloadsData(true);
    },
  },
  methods: {
    onDateSelected() {},
    getAnalytics() {
      this.loading = true;

      let fetch = {
        path: `/admin/analytics/summary`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.analyticsData = resp.data.data;

          this.infoChart.series[0].data = [
            {
              value: this.analyticsData.total_streams || 0,
              name: "Stream Count",
            },
            {
              value: this.analyticsData.total_downloads || 0,
              name: "Download Count",
            },
            {
              value: this.analyticsData.total_earnings || 0,
              name: "Earnings",
            },
            {
              value: this.analyticsData.products || 0,
              name: "Total Products",
            },
            {
              value: this.analyticsData.incoming || 0,
              name: "Total Incoming",
            },
            {
              value: this.analyticsData.artists || 0,
              name: "Total Artists",
            },
          ];

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Statistics Info",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "Statistics Info",
              text: "Unable to get Release",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },

    getStreamsData() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      this.$vs.loading({
        container: "#stream-with-loading",
        scale: 0.6,
      });

      let startDate = moment(new Date(this.dateRange.startDate)).format(
        "YYYY-MM-D"
      );
      let endDate = moment(new Date(this.dateRange.endDate)).format(
        "YYYY-MM-D"
      );

      let fetch = {
        path: `/admin/analytics/filtering?start_date=${startDate}&end_date=${endDate}&sale_type=stream&selection_type=dsp`,
      };

      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.streamsData = resp.data.data;
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");

          this.streamChart.yAxis.data = [];
          this.streamChart.series[0].data = [];

          for (const element of this.streamsData.list) {
            this.streamChart.yAxis.data.push(element.name);
            this.streamChart.series[0].data.push(element.totals.count);
          }
        })
        .catch((err) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          if (err.response) {
            this.$vs.notify({
              title: "Statistics Info",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "Statistics Info",
              text: "Unable to get Release",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        })
        .finally(() => {
          this.$vs.loading.close("#stream-with-loading > .con-vs-loading");
        });
    },

    getDownloadsData() {
      this.$vs.loading({
        container: "#download-with-loading",
        scale: 0.6,
      });

      let startDate = moment(
        new Date(this.dateRangeDownloads.startDate)
      ).format("YYYY-MM-D");

      let endDate = moment(new Date(this.dateRangeDownloads.endDate)).format(
        "YYYY-MM-D"
      );

      let fetch = {
        path: `/admin/analytics/filtering?start_date=${startDate}&end_date=${endDate}&sale_type=download&selection_type=dsp`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.DownloadsData = resp.data.data;
          this.$vs.loading.close("#download-with-loading > .con-vs-loading");

          this.downloadChart.yAxis.data = [];
          this.downloadChart.series[0].data = [];

          for (const element of this.DownloadsData.list) {
            this.downloadChart.yAxis.data.push(element.name);
            this.downloadChart.series[0].data.push(element.totals.count);
          }
        })
        .catch((err) => {
          this.$vs.loading.close("#download-with-loading > .con-vs-loading");
          if (err.response) {
            this.$vs.notify({
              title: "Statistics Info",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "Statistics Info",
              text: "Unable to get Release",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
  },
};
</script>
<style lang="scss">
.rangePicker {
  width: 100%;
  .reportrange-text {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background: white;
    height: 40px;
    font-size: 13px;
    text-align: left;
    padding-top: 9px !important;
  }
}

.streamChart,
.infoChart {
  min-height: 300px;
  width: 100%;
  > div:first-child {
    width: 100% !important;
    > canvas {
      width: 100% !important;
    }
  }
}
</style>
